<template>
  <div id="contact">
    <div class="contactUsContainer row">
      <div class="form">
        <div class="formTitle">
          <div class="firstTitle">
            {{$t('contactUs.title')}}
          </div>
          <div class="description">
            {{$t('contactUs.description')}}
          </div>
        </div>
        <div class="formInpts">
          <form @submit.prevent="submit">
            <div class="row nameContainer">
              <div class=" name col-6">
                <input type="text" :placeholder="$t('contactUs.name')" class=" inputFiled" v-model="name" @input="fullNameValidation">
                <div v-if="submited" class="validationError">{{nameValidation}}</div>
              </div>
              <div  class="col-6 city">
<!--                <v-select-->
<!--                  class="city"-->
<!--                  :items="cityes"-->
<!--                  :placeholder="$t('contactUs.city')"-->
<!--                  outlined-->
<!--                ></v-select>-->
<!--                <vue-simple-select-->
<!--                 v-model="selected"-->
<!--                 :options="cityes"-->
<!--                 class="city"-->
<!--                 :placeholder="$t('contactUs.city')"-->
<!--                />-->
                <Dropdown v-model="selectedCity" :options="cities" optionLabel="name" :placeholder="$t('contactUs.city')" :dir="test" @change="cityValidetion"/>
                <div v-if="submited"  class="validationError">{{cityError}}</div>
              </div>
            </div>
            <div class="row nameContainer">
              <div class=" name col-12">
                <input type="text" :placeholder="$t('contactUs.phoneNumber')" class=" inputFiled" v-model="phoneNumber" @input="numberValidation">
                <div v-if="submited" class="validationError">{{phoneNumberValidation}}</div>
              </div>
            </div>
            <div class="row">
              <div  class="col-12 textMessage">
                <textarea type="text" :placeholder="$t('contactUs.message')" class=" textareaFiled" v-model="message" @input="messageValidation"></textarea>
                <div v-if="submited"  class="validationError" style="margin: -0.5vh">{{messageError}}</div>
              </div>
            </div>
            <div class="row">
              <div class="d-flex">
                <button class="submitButton" @click="submit">
                  <span class="sendText"> {{$t('contactUs.send')}}</span> <span><img src="../../assets/icons/send.svg" style="height: 20px ; width: 20px;"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="mapContainer">
        <a href="https://www.google.com" target="_blank"><div>
        <img src="../../assets/icons/mapLocation.svg" style="position: absolute;top: 30%; right: 35%; height: 40px ; width: 40px; cursor: pointer">
        </div>
        </a>
<!--        <div class="map">-->
<!--          <img src="../../assets/images/map.png" class="mapImage">-->
<!--        </div>-->
      </div>
    </div>
<!--    <div class="footer">-->
<!--      <div class="footerContainer row">-->
<!--&lt;!&ndash;        <div class="d-flex group2 icons-small" style="color: red">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="social-ico" src="../../assets/icons/facebook.svg">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="social-ico" src="../../assets/icons/linkedIn.svg">&ndash;&gt;-->
<!--&lt;!&ndash;          <img class="social-ico" src="../../assets/icons/twitter.svg">&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="col-6 d-flex group1">-->
<!--          <div class="email d-flex justify-center align-center">-->
<!--            <div class="footerTitle"><span class="hideTitle" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="width: 29px;"></span></div>-->
<!--            <div class="footerText">011-4070</div>-->
<!--&lt;!&ndash;            <div class="footerTitle" style="padding-bottom: 0.5vh"><span style="display: flex; justify-content: center; align-items: center; margin: 0 8px">{{$t('contactUs.quickLinks')}}</span><img src="../../assets/icons/fastLink.svg" style="width: 20px ; height: 21px"></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">{{$t('contactUs.branches')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">{{$t('contactUs.about')}}</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">{{$t('contactUs.booking')}}</div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="email hideTitle d-flex justify-center align-center">-->
<!--            <div class="footerTitle"><span class="" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.email')}}</span><span><img src="../../assets/icons/email.svg" style="width: 22px;"></span></div>-->
<!--            <div class="footerText">info@doroob-sy.com</div>-->
<!--            &lt;!&ndash;            <div class="footerTitle" style="margin-top: 1rem"><span style="display: flex; justify-content: center; align-items: center">{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="height: 27px ; width: 37px;"></span></div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">011-4070</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="phone">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerTitle"><span style="display: flex; justify-content: center; align-items: center"><div class="footerPoint"></div>{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="height: 27px ; width: 37px;"></span></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">011-4070</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="footerText">011 321 0002</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="footerText">011 321 0002</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--&lt;!&ndash;        <div class="d-flex group2 icons-large" style="color: red; visibility: hidden">&ndash;&gt;-->
<!--&lt;!&ndash;          <a href="https://www.facebook.com" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/facebook.svg" style="cursor: pointer"></a>&ndash;&gt;-->
<!--&lt;!&ndash;          <a href="https://www.linkedin.com" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/linkedIn.svg" style="cursor: pointer"></a>&ndash;&gt;-->
<!--&lt;!&ndash;          <a href="https://www.twitter.com" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/twitter.svg" style="cursor: pointer"></a>&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <div class="col-6 d-flex justify-end group3">-->
<!--          <div class="email hideTitle d-flex justify-center align-center">-->
<!--            <div class="footerTitle"><span class="" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.socialLink')}}</span><span><img src="../../assets/icons/links.svg" style="width: 22px;"></span></div>-->
<!--&lt;!&ndash;            <div class="footerText">info@doroob-sy.com</div>&ndash;&gt;-->
<!--            <div class="d-flex group2 icons-large" style="color: red">-->
<!--              <a href="https://www.facebook.com/people/Doroob/61553104048638/?mibextid=ZbWKwL" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/facebook.svg" style="cursor: pointer"></a>-->
<!--              <a href="https://www.instagram.com/doroob258/?igsh=NWxlMnd3M29qcGw3" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/instagram.svg" style="cursor: pointer"></a>-->
<!--              <a href="https://www.youtube.com/@Doroob-gt9yy" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/youtube.svg" style="cursor: pointer"></a>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="footerTitle" style="margin-top: 1rem"><span style="display: flex; justify-content: center; align-items: center">{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="height: 27px ; width: 37px;"></span></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">011-4070</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="email smallEmail d-flex justify-center align-center" style="margin: 0 2vw ">-->
<!--            <div class="footerTitle"><span class="hideTitle" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.email')}}</span><span><img src="../../assets/icons/email.svg" style="width: 22px;"></span></div>-->
<!--            <div class="footerText">info@doroob-sy.com</div>-->
<!--            &lt;!&ndash;            <div class="footerTitle" style="margin-top: 1rem"><span style="display: flex; justify-content: center; align-items: center">{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="height: 27px ; width: 37px;"></span></div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">011-4070</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--            &lt;!&ndash;            <div class="footerText">Doroob-co@gmail.com</div>&ndash;&gt;-->
<!--          </div>-->
<!--&lt;!&ndash;          <div class="fax">&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerTitle"><span style="margin: 0 15px; display: flex; justify-content: center; align-items: center"><div class="footerPoint"></div>{{$t('contactUs.fax')}}</span><span><img src="../../assets/icons/fax.svg" style="height: 20px ; width: 20px;"></span></div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="footerText dir-en">011-4070</div>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="footerText">011 321 0002</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="footerText">011 321 0002</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;          </div>&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="footer">
      <div class="row footerContent">
        <div class="col-6 firstSide">
        <div class=" d-flex justify-start">
          <div class="email d-flex justify-center align-center">
            <div class="footerTitle hideTitle"><span class="hideTitle" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.phone')}}</span><span><img src="../../assets/icons/phone.svg" style="width: 29px;"></span></div>
            <div class="footerText">011-4070</div>
          </div>
        </div>
          <div class="d-flex justify-start">
            <div class="d-flex justify-center align-center">
              <div class="footerTitle hideTitle"><span class="hideTitle" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.email')}}</span><span><img src="../../assets/icons/email.svg" style="width: 22px;"></span></div>
              <div class="footerText">info@doroob-sy.com</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="secoundSide d-flex justify-end align-center">
            <div class="footerTitle hideTitle"><span class="" style="margin: 0 8px;display: flex; justify-content: center; align-items: center;">{{$t('contactUs.socialLink')}}</span><img src="../../assets/icons/links.svg" style="width: 22px;"></div>
            <div class="d-flex group2 icons-large" style="color: red">
              <a href="https://www.facebook.com/people/Doroob/61553104048638/?mibextid=ZbWKwL" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/facebook.svg" style="cursor: pointer"></a>
              <a href="https://www.instagram.com/doroob258/?igsh=NWxlMnd3M29qcGw3" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/instagram.svg" style="cursor: pointer"></a>
              <a href="https://www.youtube.com/@Doroob-gt9yy" target="_blank" style="cursor: default"><img class="social-ico" src="../../assets/icons/youtube.svg" style="cursor: pointer"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyRight">
      <div class="companyName">
        <div>
          <span class="copyRightText">{{$t('contactUs.copyRight')}}</span>
          <span class="year">{{copyRight}}@</span>
        </div>
      </div>
<!--      <div class="copyRightTitle">-->
<!--        <span class="year">{{copyRight}}@</span>-->
<!--      </div>-->
      <div class="certification">
        <div class="certification1">
          <span class="copyRightText" style="text-decoration: underline">{{$t('contactUs.privacyPolicy')}}</span>
        </div>
        <div class="certification2">
          <span class="copyRightText" style="text-decoration: underline">{{$t('contactUs.terms')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown'
// import VueSimpleSelect from 'vue-simple-custom-select'
import $ from 'jquery'
export default {
  name: 'contact',
  components: { Dropdown },
  // components: { VueSimpleSelect },
  data () {
    return {
      name: '',
      nameValidation: '',
      cityError: '',
      message: '',
      messageError: '',
      submited: false,
      test: '',
      phoneNumber: '',
      phoneNumberValidation: '',
      selectedCity: null,
      cities: [
        {
          name: this.$t('contactUs.damas'),
          code: 'NY'
        },
        {
          name: this.$t('contactUs.homs'),
          code: 'RM'
        },
        {
          name: this.$t('contactUs.latakia'),
          code: 'LDN'
        },
        {
          name: this.$t('contactUs.aleppo'),
          code: 'IST'
        },
        {
          name: this.$t('contactUs.hama'),
          code: 'IST'
        },
        {
          name: this.$t('contactUs.tartous'),
          code: 'LDN'
        }
      ],
      // cityes: [
      //   {
      //     label: this.$t('contactUs.damas'),
      //     value: 'Damascus'
      //   },
      //   {
      //     label: this.$t('contactUs.homs'),
      //     value: 'Homs'
      //   },
      //   {
      //     label: this.$t('contactUs.tartous'),
      //     value: 'Tartous'
      //   },
      //   {
      //     label: this.$t('contactUs.aleppo'),
      //     value: 'Aleppo'
      //   }],
      cityes: [this.$t('contactUs.damas'), this.$t('contactUs.homs'), this.$t('contactUs.tartous'), this.$t('contactUs.aleppo')],
      selected: {},
      copyRight: new Date().getFullYear()
    }
  },
  mounted () {
    this.ali()
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      if (this.$vuetify.rtl === true) {
        this.test = 'ltr'
      } else if (this.$vuetify.rtl === false) {
        this.test = 'rtl'
      }
    })
    // console.log('ali', this.copyRight)
    this.arabic()
    this.$watch(() => {
      return this.$vuetify.rtl
    }, () => {
      this.arabic()
    })
    this.$watch(() => {
      return this.$vuetify.rtl
    }, val => {
      this.cities[0].name = this.$t('contactUs.damas')
      this.cities[1].name = this.$t('contactUs.homs')
      this.cities[2].name = this.$t('contactUs.latakia')
      this.cities[3].name = this.$t('contactUs.aleppo')
      this.cities[4].name = this.$t('contactUs.hama')
      this.cities[5].name = this.$t('contactUs.tartous')
      this.submited = false
      this.name = ''
      this.message = ''
      this.selectedCity = null
      // this.nameValidation = this.$t('contactUs.nameReq')
      // this.nameValidation = this.$t('contactUs.nameVal')
      // this.cityError = this.$t('contactUs.cityReq')
      // this.messageError = this.$t('contactUs.messageReq')
    })
  },
  methods: {
    ali () {
      if (this.$vuetify.rtl === true) {
        this.test = 'ltr'
      } else if (this.$vuetify.rtl === false) {
        this.test = 'rtl'
      }
    },
    arabic () {
      if (this.$vuetify.rtl === false) {
        $('.formInpts').css('margin', '-15px 0px 0px 2vw')
        $('.formTitle').css('padding', '5vh 2vw 2vh 2vw')
        // $('.vue-simple-select-container .vue-simple-select-icon').css('margin-right', '0 !important')
        // $('.vue-simple-select-container .vue-simple-select-icon').css('margin-left', 'auto !important') .city .vue-simple-select-container .vue-simple-select-placeholder
        $(' .city .vue-simple-select-selected-option').addClass('enArrow')
        $(' .city .vue-simple-select-selected-option').removeClass('arabicArrow')
        $(' .city .vue-simple-select-container .vue-simple-select-placeholder').addClass('enArrow')
        $(' .city .vue-simple-select-container .vue-simple-select-placeholder').removeClass('arabicArrow')
      } else {
        $('.formInpts').css('margin', '-15px 2vw 0px 0px')
        $('.formTitle').css('padding', '5vh 2vw 2vh 2vw')
        $('.city .vue-simple-select-selected-option').addClass('arabicArrow')
        $(' .city .vue-simple-select-selected-option').removeClass('enArrow')
        $('.city .vue-simple-select-container .vue-simple-select-placeholder').addClass('arabicArrow')
        $(' .city .vue-simple-select-container .vue-simple-select-placeholder').removeClass('enArrow')

        // $(' .city .vue-simple-select-container .vue-simple-select-icon').addClass('test')
      }
    },
    fullNameValidation () {
      const fullNameReg = /^[a-zA-Z\s]+$/
      // console.log('name', this.fullName)
      if (this.name === '') {
        this.nameValidation = this.$t('contactUs.required')
        return false
      } else if (fullNameReg.test(this.name) === false) {
        this.nameValidation = this.$t('contactUs.nameVal')
        return false
      } else {
        this.nameValidation = ''
        return true
      }
    },
    numberValidation () {
      const number = /^[+]?[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im
      // console.log('name', this.fullName)
      if (this.phoneNumber === '') {
        this.phoneNumberValidation = this.$t('contactUs.required')
        return false
      } else if (number.test(this.phoneNumber) === false) {
        this.phoneNumberValidation = this.$t('contactUs.phoneVal')
        return false
      } else {
        this.phoneNumberValidation = ''
        return true
      }
    },
    cityValidetion () {
      if (this.selectedCity === null) {
        this.cityError = this.$t('contactUs.required')
        // console.log('city null', this.selectedCity)
        return false
      } else {
        this.cityError = ''
        // console.log('city not null', this.selectedCity)
        return true
      }
    },
    messageValidation () {
      if (this.message === '') {
        this.messageError = this.$t('contactUs.required')
        return false
      } else {
        this.messageError = ''
        return true
      }
    },
    submit () {
      this.submited = true
      this.fullNameValidation()
      this.cityValidetion()
      this.numberValidation()
      this.messageValidation()
    }
  }

}
</script>

<style scoped>
/*.dir-ar {*/
/*  direction: rtl*/
/*}*/
.dir-en {
  direction: ltr
}
  #contact{
    margin-top: -70px;
    padding-top: 70px;
    background-color: #121118 ;
  }
  a img {
    width: 22px; height: 22px;
  }
  .contactUsContainer{
    display: flex;
  }
  .mapContainer{
    /*margin: 32px 25px 25px 25px;*/
    /*width: 100%;*/
    /*height: 100%;*/
    position: relative;
    background-image: url("../../assets/images/map.png");
    margin: 14px 0 -7px 0px;
    background-size: cover;
  }
  .firstTitle{
    font-family: lama-extraBoldExpanded;
    font-size: 30px;
    color: #FFAC00;
  }
  .description{
    color: #FFFFFF;
  }
  .inputFiled{
    border: #FFAC00 2px solid;
    border-radius: 20px;
    width: 100%;
    height: 50px;
    padding: 15px;
    color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .textareaFiled{
    border: #FFAC00 2px solid;
    border-radius: 20px;
    width: 100%;
    height: 170px;
    padding: 15px;
    color: white;
  }
  .textMessage{
    height: 200px
  }
  .validationError{
    font-size: 10px;
    font-family: 'lama-bold';
    padding: 0.3vh 1.5vw;
    display: flex;
    justify-content: flex-start;
    color: #bf0505;
  }
  .textareaFiled::placeholder,
  .inputFiled::placeholder{
    color: white;
    font-family: lama-regular;
    font-size: 13px;
  }
  textarea:focus, input:focus{
    outline: none;
  }
  textarea {
    resize: none;
  }
  .formInpts{
    height: 30rem;
    width: 35rem;
    /*background-color: wheat;*/
    padding: 25px 15px;
  }
  .formTitle{
    /*padding: 5vh 6vw 2vh 2vw;*/
    text-align: start;
  }
  .footer{
    /*height: 20rem;*/
    /*border-top: #FFFFFF 1px solid;*/
    /*border-bottom: #FFFFFF 1px solid;*/
    /*margin: 0 2vw;*/
    padding: 35px 30px;
  }
  .footerContent{
    border-top: #FFFFFF 1px solid;
    border-bottom: #FFFFFF 1px solid;
    padding: 25px 0px;
  }
  .firstSide {
    display: flex;
  }
  .secoundSide {
    height: 100%;
  }
@media (max-width: 1400px) {
  .firstSide {
    display: block;
  }
}
  .copyRight{
    display: flex;
    padding: 20px 3vw;
  }
  .year{
    color: #FFAC00;
    padding: 10px;
    font-size: 11px;
  }
  .copyRightText{
    color: #FFFFFF;
    font-size: 11px;
  }
  .companyName{
    display: flex;
    justify-content: flex-start;
    /*width: 31.5vw;*/
    width: 50vw;
    align-items: center;
  }
  .copyRightTitle{
    display: flex;
    justify-content: center;
    width: 28vw;
    align-items: center;
  }
  .certification{
    display: flex;
    justify-content: flex-end;
    /*width: 32vw;*/
    width: 50vw;
    align-items: center;
  }
  .submitButton{
    height: 55px;
    width: 125px;
    background-color: #FFAC00;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  .sendText{
    padding: 0 10px;
    text-decoration: underline;
    font-family: lama-extraBoldExpanded;
    font-size: 12px;
  }
  .footerContainer{
    display: flex;
    justify-content: center;
  }
  .footerPoint{
    width: 5px;
    height: 5px;
    border-radius: 50px;
    margin: 0px 5px;
    background-color: #FFAC00;
    margin-top: 5px;
  }
  .footerText{
    color: white;
    font-size: 13px;
    min-width: 125px;
    display: flex;
  }
  .footerTitle{
    font-weight: bold;
    color: #FFAC00;
    /*padding-bottom: 1.5vh;*/
    font-family: lama-bold-expanded !important;
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0 25px;
    min-width: 145px;
  }
  .footerTitle span {
    text-decoration: underline;
  }
  .fastLinks,
  .phone,
  .email,
  .fax{
    /*width: 25vw;*/
  }
  .certification1,
  .certification2{
    margin: 0 15px;
  }
  .nameContainer{
    height: 85px;
  }
  .mapImage{
    float: left;
    padding: 35px;
    height: 615px;
    width: 940px;
  }
  .form,
  .mapContainer{
    flex: 0 0 auto;
    width: 50%;
  }
  /*.group1,*/
  /*.group2, .group3 {*/
  /*  flex: 0 0 auto;*/
  /*  width: 33.3333333%;*/
  /*  justify-content: center; align-items: flex-start;*/
  /*}*/
/*.group2 {*/
/*  padding-top: 10px;*/
/*  align-items: flex-start!important;*/
/*}*/
.group1 .footerText {
  /*margin: 10px;*/
  /*padding-bottom: 10px;*/
}
  .social-ico {
    margin: 0 2vw;
  }
  @media (max-width: 380px) {
    .footerText {
      font-size: 12px;
    }
    .footerTitle{
      font-size: 13px;
    }
    .copyRightText,
    .year{
      font-size: 9px;
    }
  }
  @media (max-width: 550px) {
    .copyRight {
      padding: 20px 2vw;
      font-size: xx-small;
    }
    .certification1,
    .certification2{
      margin: 0 10px;
    }
  }
  @media (max-width: 760px) {
    .group1,
    .group2 {
      /*flex: 0 0 auto;*/
      /*width: 100%;*/
    }
    .fastLinks,
    .phone,
    .email,
    .fax{
      /*width: 50vw;*/
      padding: 1vh 1vw;
    }
  }
.smallEmail {
  display: none !important;
}
  @media (max-width: 900px) {
    .form,
    .mapContainer{
      flex: 0 0 auto;
      width: 100%;
    }
    .mapContainer{
     height: 500px;
      margin-top: 30px;
    }
    .formTitle{
      padding: 5vh 4vw 1vh 4vw !important;
      text-align: center;
    }
    .formInpts{
      width: 100% !important;
      /*display: inline-flex;*/
      /*justify-content: center;*/
      margin: 0 0 35px 0!important;
      height: 25rem !important;
      padding: 25px 8vw 25px 8vw;
    }
    .mapImage{
      width: 100vw !important;
      height: 30rem;
      /*margin-top: 30px;*/
    }
    .copyRightText{
      color: #FFFFFF;
      font-size: 10px;
    }
    .year{
      font-size: 9px;
    }
    .hideTitle {
      display: none !important;
    }
    .footerTitle{
      min-width: unset;
    }
    .smallEmail {
      display: flex !important;
    }
  }
  @media (max-width: 1680px) {
    .mapImage{
      width: 800px;
    }
  }
  @media (max-width: 1530px) {
    .mapImage{
      width: 650px;
    }
  }
  @media (max-width: 1390px) {
    .mapImage{
      width: 550px;
    }
    .group1{
      display: block !important;
    }
    .group1 .email{
      justify-content: start !important;
    }
  }
  @media (max-width: 1000px) {
    .mapImage{
      width: 450px;
    }
  }
  @media (max-width: 1250px) {
    .formInpts {
      width: 33rem;
    }
  }
  @media (max-width: 1180px) {
    .formInpts {
      width: 30rem;
    }
  }
  @media (max-width: 1080px) {
    .formInpts {
      width: 27rem;
    }
  }
  @media (max-width: 700px) {
    .firstTitle {
      font-size: 25px;
    }
    .description{
      font-size: 15px;

    }
  }
  @media (max-width: 500px) {
    .mapImage {
      height: 20rem;
    }
  }
@media screen and (max-width: 600px){
  /*.icons-small {*/
  /*  display: block !important;*/
  /*}*/
  /*.icons-large{*/
  /*  !*display: none !important;*!*/
  /*}*/
  /*.group2 {*/
  /*  width: 100%!important;*/
  /*}*/
  /*.group1, .group3 {*/
  /*  width: 50%!important;*/
  /*}*/
  /*.social-ico {*/
  /*  margin: 10vw!important;*/
  /*}*/
}
@media screen and (min-width: 600px){
  /*.icons-small {*/
  /*  display: none!important;*/
  /*}*/
  .icons-large {
    /*display: block!important;*/
  }
}
</style>
<style lang="scss">
  /*@import "~primevue/resources/themes/saga-blue/theme.css";*/
  @import "~primevue/resources/primevue.min.css";
  @import "~primeicons/primeicons.css";
/*  .city .v-text-field--outlined, .v-text-field--solo {*/
/*    border-radius: 20px !important;*/
/*    border: #FFAC00 2px solid !important;*/
/*    height: 50px !important;*/
/*  }*/
/*  .city .vue-simple-select-container .vue-simple-select-placeholder{*/
/*    color: white !important;*/
/*    font-size: 13px !important;*/
/*    font-family: lama-regular !important;*/
/*  }*/
/*.vue-simple-select-container .vue-simple-select-button:focus-visible{*/
/*    outline: none !important;*/
/*    border: #FFAC00 2px solid !important;*/
/*  }*/
/*  .vue-simple-select-container .vue-simple-select-button:focus{*/
/*    outline: none !important;*/
/*    border: #FFAC00 2px solid !important;*/
/*  }*/
/*  .vue-simple-select-container .vue-simple-select-dropdown{*/
/*    background-color: white !important;*/
/*    border-radius: 20px !important;*/
/*  }*/
/*  .city .vue-simple-select-container .vue-simple-select-option{*/
/*    text-align: center !important;*/
/*    border-radius: 20px !important;*/
/*  }*/
/*  .vue-simple-select-container .vue-simple-select-button{*/
/*    color: #FFFFFF !important;*/
/*    font-size: 13px !important;*/
/*    font-family: lama-regular;*/
/*  }*/
/*  .vue-simple-select-container .vue-simple-select-button{*/
/*    padding: 15px !important;*/
/*  }*/
/*  .city .vue-simple-select-container .vue-simple-select-option:focus{*/
/*    outline: none !important;*/
/*  }*/
/*  .city .vue-simple-select-container .vue-simple-select-option.selected{*/
/*    !*background-color: #FFAC00;*!*/
/*  }*/
/*  .vue-simple-select-container .vue-simple-select-icon{*/
/*    margin-right: 0 !important;*/
/*    margin-left: 0 !important;*/
/*  }*/
/*  .arabicArrow{*/
/*    margin-left: auto !important;*/
/*    margin-right: 0 !important;*/
/*  }*/
/*  .enArrow{*/
/*    margin-left: 0 !important;*/
/*    margin-right: auto !important;*/
/*  }*/
/*  !*.vue-simple-select-container .vue-simple-select-icon.reverse{*!*/
/*  !*  margin-left: 0 !important;*!*/
/*  !*  margin-right: auto !important;*!*/
/*  !*}*!*/
/*  !*.city .vue-simple-select-selected-option {*!*/
/*  !*  margin-left: auto;*!*/
/*  !*}*!*/
/*  .vue-simple-select-container{*/
/*    min-width: 0px !important;*/
/*  }*/
  .city .v-text-field--outlined, .v-text-field--solo {
    border-radius: 20px;
  }
  .city .v-text-field--outlined >>> fieldset {
    border-color: rgba(192, 0, 250, 0.986);
  }
  .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
    border: #FFAC00 2px solid;
  }
  .city .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 46px;
    margin-top: 5px;
    height: 46px;
  }
  .city .v-text-field {
    color: #FFAC00 !important;
  }
  .city .v-text-field input::placeholder {
    color: white;
    font-size: 13px;
  }
  .city .theme--light.v-icon{
    color: #FFAC00 !important;
    margin-top: -15px;
  }
   .theme--light.v-list {
      border-radius: 20px;
    }
    .v-select__selection--comma{
      color: white;
      font-size: 13px !important;
    }
    .v-select.v-text-field:not(.v-text-field--single-line) input {
      margin-top: -2px;
    }
  /*----------------------------------new dropDown---------------------------------*/
  .p-dropdown {
    background-color: transparent !important;
    border: #FFAC00 2px solid !important;
    border-radius: 20px !important;
    width: 100% !important;
    height: 50px !important;
    transform: rotateY(180deg) !important;
  }
  .p-dropdown .p-dropdown-panel{
    background-color: white !important;
    border-radius: 20px !important;
  }
  .p-dropdown-items{
    padding: 10px 0px !important;
    transform: rotateY(180deg) !important;
    font-size: 13px;
  }
  .p-dropdown-item {
    height: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-inputtext {
    margin: 0;
    color: white;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 15px;
    font-size: 13px;
    font-family: lama-regular;
    transform: rotateY(180deg) !important;
  }
  .pi{
    padding: 15px;
    color: white;
  }
  .p-dropdown-items-wrapper{
    max-height: 245px !important;
  }
  .p-dropdown-label{
    text-overflow: unset;
  }
</style>
